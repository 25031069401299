import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
// import { ToastContainer} from 'react-toastify';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  
    <StrictMode>
        <App />
        {/* <ToastContainer /> */}
    </StrictMode>

);
