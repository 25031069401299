
import Header from "../header";
import Footer from "../footer";
import ViewAllCourse from "./viewallcourse";
function BasicCourse(){
    return(<>
        <Header/>
        <ViewAllCourse/>
        <Footer/>
    </>)
}
export default BasicCourse;