
import Header from "../header";
import Footer from "../footer";
import ViewNiletCourse from "./viewniletcourse";

function NiletCourse(){
    return(<>
        <Header/>
        <ViewNiletCourse/>
        <Footer/>
    </>)
}
export default NiletCourse;