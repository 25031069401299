
import Header from "../header";
import Footer from "../footer";
import ViewAdvanceCourse from './viewadvancecourse';
function AdvanceCourse(){
    return(<>
        <Header/>
        <ViewAdvanceCourse/>
        <Footer/>
    </>)
}
export default AdvanceCourse;